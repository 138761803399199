import Easy from "../Easy";
import Medium from "../Medium";
import Hard from "../Hard";

import "./style.css";

import easyBg from "../../img/game/easy/bg.png";
import mediumBg from "../../img/game/medium/bg.png";
import hardBg from "../../img/game/hard/bg.png";

import score_bg from "../../img/game/score_bg.png";
import timer_bg from "../../img/game/timer_bg.png";

const Game = ({ score, lvl, imgState, handleClick, countdown }) => {
    return (
        <section
            className="game"
            style={{
                background: `url(${
                    lvl === "easy"
                        ? easyBg
                        : lvl === "medium"
                        ? mediumBg
                        : lvl === "hard"
                        ? hardBg
                        : null
                }) no-repeat center top/cover`,
            }}
        >
            <div className="wrapper">
                <div className="game__inner">
                    <div className="score-counter">
                        <p className="score-counter__text">{score}</p>
                        <img
                            src={score_bg}
                            alt="bg"
                            className="score-counter__bg"
                        />
                    </div>

                    {lvl === "easy" ? (
                        <Easy imgState={imgState} handleClick={handleClick} />
                    ) : lvl === "medium" ? (
                        <Medium imgState={imgState} handleClick={handleClick} />
                    ) : lvl === "hard" ? (
                        <Hard imgState={imgState} handleClick={handleClick} />
                    ) : null}

                    <div className="timer-counter">
                        <p className="timer-counter__text">{countdown}</p>
                        <img
                            src={timer_bg}
                            alt="bg"
                            className="timer-counter__bg"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Game;
