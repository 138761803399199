import { NavLink } from "react-router-dom";

import "./style.css";

import easy from "../../img/menu/easy.png";
import medium from "../../img/menu/medium.png";
import hard from "../../img/menu/hard.png";
import top from "../../img/menu/top.png";

const Menu = () => {
    return (
        <ul className="nav">
            <li className="nav__item">
                <NavLink
                    to={{ pathname: "play", search: "lvl=easy" }}
                    className="nav__link"
                >
                    <img src={easy} alt="btn_img" />
                </NavLink>
            </li>
            <li className="nav__item">
                <NavLink
                    to={{ pathname: "play", search: "lvl=medium" }}
                    className="nav__link"
                >
                    <img src={medium} alt="btn_img" />
                </NavLink>
            </li>
            <li className="nav__item">
                <NavLink
                    to={{ pathname: "play", search: "lvl=hard" }}
                    className="nav__link"
                >
                    <img src={hard} alt="btn_img" />
                </NavLink>
            </li>
            <li className="nav__item">
                <NavLink to="/top" className="nav__link">
                    <img src={top} alt="btn_img" />
                </NavLink>
            </li>
        </ul>
    );
};

export default Menu;
