import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useExpand, WebAppProvider } from "@vkruglikov/react-telegram-web-app";

import Home from "./pages/home";
import Top from "./pages/top";
import Play from "./pages/play";

import "./style.css";
import "./reset.css";

function App() {
    const [isExpanded, setIsExpanded] = useExpand();

    useEffect(() => {
        if (!isExpanded) setIsExpanded(true);
    }, [isExpanded, setIsExpanded]);

    return (
        <div className="App">
            <Router>
                <WebAppProvider>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/top" element={<Top />} />
                        <Route path="/play" element={<Play />} />
                    </Routes>
                </WebAppProvider>
            </Router>
        </div>
    );
}

export default App;
