import Menu from "../components/Menu";

import logo from "../img/logo.svg";

const Home = () => {
    return (
        <section className="home">
            <div className="wrapper">
                <div className="home__inner">
                    <img src={logo} alt="logo" className="logo" />
                    <Menu />
                    {/* {auth ? <Menu /> : "You need to be authorised"} */}
                </div>
            </div>
        </section>
    );
};

export default Home;
