import "./style.css";

const Score = ({ user, score }) => {
    return (
        <div className="score">
            <p className="score__user">{user}</p>
            <p className="score__count">{score}</p>
        </div>
    );
};

export default Score;
