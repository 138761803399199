import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { BackButton, useInitData } from "@vkruglikov/react-telegram-web-app";

import again from "../../img/again/again_btn.png";
import again_score from "../../img/again/score.png";
import "./style.css";

const Again = ({ score, restartGame, lvl }) => {
    const [bestScore, setBestScore] = useState(null);
    const [initDataUnsafe] = useInitData();
    const navigate = useNavigate();
    const chat_id = initDataUnsafe?.user?.id;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    "https://api.truetouch.games/api/writeresult",
                    {
                        chat_id,
                        lvl,
                        score,
                    }
                );
                setBestScore(response.data["high_score"]);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [chat_id, lvl, score]);

    return (
        <section className="again">
            <BackButton onClick={() => navigate("/")} />

            <div className="wrapper">
                <div className="again__inner">
                    <div className="again__score">
                        <div className="again__text-block">
                            <p className="again__text">{score}</p>
                            <p className="again__text">
                                {bestScore !== null ? bestScore : "..."}
                            </p>
                        </div>
                        <img src={again_score} alt="bg" className="again__bg" />
                    </div>
                    <button
                        className="again__btn"
                        onClick={() => navigate("/")}
                    >
                        <img src={again} alt="button" />
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Again;
