import { useRef, useState, useEffect } from "react";
import normal from "../../img/game/medium/btn.png";
import green from "../../img/game/medium/greenbtn.png";
import red from "../../img/game/medium/redbtn.png";

const Medium = ({ imgState, handleClick }) => {
    const countOfBtns = 2;

    const lowerButtonRef = useRef();
    const [lowerButtonWidth, setLowerButtonWidth] = useState(null);
    useEffect(() => {
        if (lowerButtonRef.current) {
            console.log(lowerButtonRef.current.offsetWidth);

            setLowerButtonWidth(lowerButtonRef.current.offsetWidth);
        }
    }, []);
    return (
        <div style={{ width: "90%" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <button
                    onClick={() => handleClick(0, countOfBtns)}
                    style={{ width: lowerButtonWidth || "auto" }}
                >
                    <img
                        src={
                            imgState === "green"
                                ? green
                                : imgState === "red"
                                ? red
                                : normal
                        }
                        alt="gamebtn"
                    />
                </button>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "min(11vw, 30px)",
                    marginTop: "-3vh"
                }}
            >
                <button
                    // style={{ transform: "rotate(235deg)" }}
                    onClick={() => handleClick(1, countOfBtns)}
                    ref={lowerButtonRef}
                >
                    <img
                        src={
                            imgState === "green"
                                ? green
                                : imgState === "red"
                                ? red
                                : normal
                        }
                        alt="gamebtn"
                        style={{ width: "15vh", transform: "rotate(235deg)" }}
                    />
                </button>
                <button
                    // style={{ transform: "rotate(125deg)" }}
                    onClick={() => handleClick(2, countOfBtns)}
                >
                    <img
                        src={
                            imgState === "green"
                                ? green
                                : imgState === "red"
                                ? red
                                : normal
                        }
                        alt="gamebtn"
                        style={{ width: "15vh", transform: "rotate(125deg)" }}
                    />
                </button>
            </div>
        </div>
    );
};

export default Medium;
