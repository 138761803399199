import normal from "../../img/game/hard/btn.png";
import green from "../../img/game/hard/greenbtn.png";
import red from "../../img/game/hard/redbtn.png";

const Hard = ({ imgState, handleClick }) => {
    const countOfBtns = 3;

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <button
                    style={{ width: "40%" }}
                    onClick={() => handleClick(0, countOfBtns)}
                >
                    <img
                        src={
                            imgState === "green"
                                ? green
                                : imgState === "red"
                                ? red
                                : normal
                        }
                        alt="gamebtn"
                    />
                </button>
                <button
                    style={{ width: "40%" }}
                    onClick={() => handleClick(1, countOfBtns)}
                >
                    <img
                        src={
                            imgState === "green"
                                ? green
                                : imgState === "red"
                                ? red
                                : normal
                        }
                        alt="gamebtn"
                        style={{ transform: "scaleX(-1)" }}
                    />
                </button>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <button style={{ width: "40%" }} onClick={() => handleClick(2)}>
                    <img
                        src={
                            imgState === "green"
                                ? green
                                : imgState === "red"
                                ? red
                                : normal
                        }
                        alt="gamebtn"
                        style={{ transform: "scaleY(-1)" }}
                    />
                </button>
                <button style={{ width: "40%" }} onClick={() => handleClick(3)}>
                    <img
                        src={
                            imgState === "green"
                                ? green
                                : imgState === "red"
                                ? red
                                : normal
                        }
                        alt="gamebtn"
                        style={{ transform: "scale(-1)" }}
                    />
                </button>
            </div>
        </div>
    );
};

export default Hard;
