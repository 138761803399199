import normal from "../../img/game/easy/btn.png";
import green from "../../img/game/easy/greenbtn.png";
import red from "../../img/game/easy/redbtn.png";

const Easy = ({ imgState, handleClick }) => {
    const countOfBtns = 1;

    return (
        <div
            style={{
                width: "40%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <div>
                <button onClick={() => handleClick(0, countOfBtns)}>
                    <img
                        src={
                            imgState === "green"
                                ? green
                                : imgState === "red"
                                ? red
                                : normal
                        }
                        alt="gamebtn"
                    />
                </button>
            </div>
            <div>
                <button onClick={() => handleClick(1, countOfBtns)}>
                    <img
                        src={
                            imgState === "green"
                                ? green
                                : imgState === "red"
                                ? red
                                : normal
                        }
                        alt="gamebtn"
                        style={{ transform: "rotate(180deg)" }}
                    />
                </button>
            </div>
        </div>
    );
};

export default Easy;
