import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import Again from "../components/Again";
import Game from "../components/Game";

const Play = () => {
    const [score, setScore] = useState(0);
    const [countdown, setCountdown] = useState(10);
    const [gameOver, setGameOver] = useState(false);
    const [imgState, setImgState] = useState("");
    const [isAnimation, setIsAnimation] = useState(false);
    const [impactOccurred] = useHapticFeedback();

    const { search } = useLocation();
    const lvl = new URLSearchParams(search).get("lvl");

    useEffect(() => {
        const timer = setTimeout(() => {
            setCountdown((prevScore) => prevScore - 1);
            if (countdown <= 1) {
                setImgState("red");
                setTimeout(() => {
                    setGameOver(true);
                }, 1000);
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [countdown]);

    const handleClick = (index, count) => {
        if (isAnimation === true) return;

        impactOccurred("medium");
        const randomNum = Math.round(Math.random() * count);

        setIsAnimation(true);
        if (randomNum === index) {
            setScore((prevScore) => prevScore + 1);
            setImgState("green");
            setTimeout(() => {
                setImgState("");
                setIsAnimation(false);
                setCountdown(10);
            }, 1000);
        } else {
            setImgState("red");
            setTimeout(() => {
                setImgState("");
                setIsAnimation(false);
                setGameOver(true);
                setCountdown(0);
            }, 1000);
        }
    };

    return gameOver ? (
        <Again score={score} lvl={lvl} />
    ) : (
        <Game
            score={score}
            lvl={lvl}
            imgState={imgState}
            handleClick={handleClick}
            countdown={countdown}
        />
    );
};

export default Play;
