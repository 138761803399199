import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButton } from "@vkruglikov/react-telegram-web-app";
import axios from "axios";

import Score from "../components/Score";

import easy from "../img/scoretabs/easy.png";
import easy_active from "../img/scoretabs/easy_active.png";
import medium from "../img/scoretabs/medium.png";
import medium_active from "../img/scoretabs/medium_active.png";
import hard from "../img/scoretabs/hard.png";
import hard_active from "../img/scoretabs/hard_active.png";

const Top = () => {
    const navigate = useNavigate();

    const [activeButton, setActiveButton] = useState("easy");
    const [scoreboard, setScoreboard] = useState(null);

    const buttons = [
        { id: "easy", normal: easy, active: easy_active, alt: "easybtn" },
        {
            id: "medium",
            normal: medium,
            active: medium_active,
            alt: "mediumbtn",
        },
        { id: "hard", normal: hard, active: hard_active, alt: "hardbtn" },
    ];

    const fetchScoreboard = async (lvl) => {
        try {
            const response = await axios.post(
                "https://api.truetouch.games/api/getScoreboard",
                { lvl }
            );
            setScoreboard(response.data["scoreboard"]);
        } catch (error) {
            console.error("Error fetching scoreboard data:", error);
        }
    };
    useEffect(() => {
        setScoreboard(null);
        fetchScoreboard(activeButton);
    }, [activeButton]);

    return (
        <section className="top">
            <BackButton onClick={() => navigate("/")} />
            <div className="wrapper">
                <div className="tabs">
                    <div className="tabs__btns">
                        {buttons.map((button) => (
                            <button
                                className="tabs__btn"
                                onClick={() => setActiveButton(button.id)}
                            >
                                <img
                                    key={button.id}
                                    src={
                                        activeButton === button.id
                                            ? button.active
                                            : button.normal
                                    }
                                    alt={button.alt}
                                />
                            </button>
                        ))}
                    </div>
                    <div className="tabs__item">
                        {scoreboard !== null ? (
                            scoreboard.map((item) => (
                                <Score user={item[0]} score={item[1]} />
                            ))
                        ) : (
                            <center>Loading...</center>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Top;
